<template>
  <div class="home-container">
    <!--首页 第一模块-->
    <div class="home-first">
      <div class="home-first-left">
        <img class="home-first-left-img" src="../assets/home/icon_shouyi.png" />
        <div class="home-first-left-title">总收益</div>
        <div class="home-first-left-price">¥ {{ homeObj.grandPrice }}</div>
      </div>
      <div class="home-first-right">
        <img
          class="home-first-right-img"
          src="../assets/home/icon_tuandui.png"
        />
        <div class="home-first-right-title">团队人数</div>
        <div class="home-first-right-price">{{ homeObj.userNum }}</div>
      </div>
    </div>

    <!--首页 第二模块-->
    <div class="home-second">
      <div class="home-second-block">
        <div class="home-second-block-left">
          <div class="second-block-left-title">当日收益（元）</div>
          <div class="second-block-left-price">¥ {{ homeObj.todayPrice }}</div>
        </div>
        <div class="home-second-block-right">
          <el-date-picker
            v-model="modificationValue"
            @change="modificationChang"
            type="month"
            value-format="yyyy-MM"
            :clearable="false"
            placeholder="选择月"
          >
          </el-date-picker>
        </div>
      </div>
      <!-- 折线图统计表 -->
      <div class="home-second-chart">
        <div id="earningsChart" style="width: 100%; height: 23.125rem"></div>
      </div>
    </div>

    <!--首页 第三模块-->
    <div class="home-third">
      <div class="home-third-left">
        <div class="home-third-left-disflay">
          <div class="home-third-left-block">
            <div class="left-block-people">
              <div class="left-block-people-title">当日新增团队人数（人）</div>
              <div class="left-block-people-text">
                {{ homeObj.todayUserNum }}
              </div>
            </div>
          </div>
          <div class="home-third-left-right">
            <el-date-picker
              v-model="detaPickerValue"
              @change="detaPickerChang"
              type="month"
              :clearable="false"
              value-format="yyyy-MM"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>

        <!--当日新增统计表-->
        <div class="home-third-left-day">
          <div id="dayNewlyChart" style="width: 100%; height: 18.75rem"></div>
        </div>
      </div>
      <div class="home-third-right">
        <div class="home-third-right-top">
          <div class="third-right-top-left">消息通知栏</div>
          <div class="third-right-top-right" @click="allInformationBtn">
            全部 <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <div class="home-third-right-bottom">
          <div
            class="third-right-bottom-list"
            v-for="(item, index) in messageArray"
            :key="index"
            @click="allInformationBtn"
          >
            <div class="bottom-list-div">
              <div class="bottom-list-div-title">
                <img
                  class="list-div-img"
                  :src="
                    item.isRead == 1
                      ? require('../assets/home/small.png')
                      : require('../assets/home/smallActive.png')
                  "
                />
                <div class="list-div-title">
                  {{ item.informationTitle }}
                </div>
              </div>
              <div class="bottom-list-div-time">
                {{ item.createTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--公共底部模块-->
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from '../components/bottom.vue'
import { priceIndex, userIndex, statistics, getInformation } from '../api/index'
import { getDateTime } from '../utils/time'

export default {
  components: {
    bottom,
  },
  data() {
    return {
      // 月份日期选择
      modificationValue: '',
      // 每日日期选中
      detaPickerValue: '',
      // 首页公共数据对象
      homeObj: {},
      // 第三模块 消息数组
      messageArray: [],
    }
  },
  created() {
    this.modificationValue = getDateTime('month')
    this.detaPickerValue = getDateTime('month')
    // 从首页进入折线图、金额
    this.homeEarnings()
    // 从首页进入第二模块折线图、人数
    this.homegetcolumnar()
    // 首页请求公共统计页面
    this.homestatistics()
    // 首页第二模块信息接口
    this.homemessage()
  },

  mounted() {},
  methods: {
    // 首页公共统计接口获取数据
    homestatistics() {
      let params = {}
      statistics(params).then((res) => {
        if (res.data.code == 200) {
          this.homeObj = res.data.content
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 首页第一模块 点击选择获取月份
    modificationChang(val) {
      if (val) {
        var year = val.split('-')[0]
        var month = val.split('-')[1]
        this.modificationValue = year + '-' + month
        // 触发 月份
        this.homeEarnings()
      }
    },
    // 首页第一模块 收益折现统计图 列表数据
    homeEarnings() {
      let params = {
        priceMonth: this.modificationValue,
      }
      priceIndex(params).then((res) => {
        if (res.data.code == 200) {
          let contentArray = res.data.content

          // 时间数组排序
          var earningsChartArrayTiem = []
          // 这个月每天的金额
          var earningsChartArrayPrice = []
          contentArray.forEach((item, index) => {
            earningsChartArrayTiem.push(item.date.slice(-2))
            earningsChartArrayPrice.push(item.price)
          })

          this.$nextTick(() => {
            this.earnings(earningsChartArrayTiem, earningsChartArrayPrice)
          })
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 首页收益统计图
    earnings(time, price) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('earningsChart'))
      // 绘制图表
      myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
          },
          calculable: true,
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: time,
              axisLine: {
                show: false,
                // 坐标轴线
                lineStyle: {
                  color: '#999999',
                },
              },
              axisTick: {
                //刻度线
                show: false,
                lineStyle: {
                  color: '#999999',
                },
              },
            },
          ],
          grid: {
            left: '4%',
            right: '4%',
            top: '60px',
            bottom: '20%',
          },
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                formatter: '{value}',
              },
              splitLine: {
                show: false, //不显示分隔线
              },
            },
          ],
          color: ['#DCE7FC'],
          series: [
            {
              symbol: 'none',
              name: '收益(￥)',
              type: 'line',
              color: ['#29D647'],
              smooth: true,
              data: price,
              itemStyle: {
                normal: {
                  color: '#29D647',
                  lineStyle: {
                    width: 3, //设置线条粗细
                    shadowOffsetX: 0, // 折线的X偏移
                    shadowOffsetY: 4, // 折线的Y偏移
                    shadowBlur: 8, // 折线模糊
                    shadowColor: 'rgba(41, 214, 71, 1)', //折线颜色
                  },
                },
              },
            },
          ],
        },
        true
      )
    },
    // 首页第二模块 点击选择获取月份
    detaPickerChang(val) {
      if (val) {
        var year = val.split('-')[0]
        var month = val.split('-')[1]
        this.detaPickerValue = year + '-' + month
        // 触发 月份
        this.homegetcolumnar()
      }
    },
    // 收益第二模块  当月新增人数
    homegetcolumnar() {
      let params = {
        userMonth: this.detaPickerValue,
      }

      userIndex(params).then((res) => {
        if (res.data.code == 200) {
          let userNumberArray = res.data.content
          // 用户月份时间
          var userNumberTime = []
          // 用户每天新增人数
          var userNumerpeople = []

          userNumberArray.forEach((item, index) => {
            userNumberTime.push(item.date.slice(-2))
            userNumerpeople.push(item.userNum)
          })
          this.$nextTick(() => {
            this.columnar(userNumberTime, userNumerpeople)
          })
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 首页第二模块 当月新增人数折线图
    columnar(time, people) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('dayNewlyChart'))
      // 绘制图表
      myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['人数'],
          },
          color: ['#FFBA36'],
          calculable: true,

          xAxis: [
            {
              type: 'category',
              data: time,
              axisLine: {
                show: false,
                // 坐标轴线
                lineStyle: {
                  color: '#999999',
                },
              },
              axisTick: {
                //刻度线
                show: false,
                lineStyle: {
                  color: '#999999',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: {
                show: false, //不显示分隔线
              },
            },
          ],
          grid: {
            left: '5%',
            right: '4%',
            top: '17%',
            bottom: '10%',
          },
          series: [
            {
              name: '人数',
              barWidth: 6.68,
              distance: 5, // 距离
              type: 'line',
              smooth: true,
              symbol: 'none',
              data: people,
              itemStyle: {
                normal: {
                  color: '#FFBA36',
                  lineStyle: {
                    width: 3, //设置线条粗细
                    shadowOffsetX: 0, // 折线的X偏移
                    shadowOffsetY: 4, // 折线的Y偏移
                    shadowBlur: 8, // 折线模糊
                    shadowColor: '#FFBA36', //折线颜色
                  },
                },
              },
            },
          ],
        },
        true
      )
    },
    // 点击全部跳转到对应的消息列表
    allInformationBtn() {
      this.$router.push('information')
    },
    // 第二模块右下角信息列表
    homemessage() {
      let params = {}
      getInformation(params).then((res) => {
        if (res.data.code == 200) {
          this.messageArray = res.data.content.informationList.slice(0, 4)
          localStorage.setItem('isReadIndex', res.data.content.isRead)
          this.$store.commit('isReadBtn', res.data.content.isRead)
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.home-container {
  margin: 20px;
  .home-first {
    display: flex;
    .home-first-left,
    .home-first-right {
      width: 50%;
      height: 90px;
      display: flex;
      align-items: center;
      background: #ffffff;
      padding: 0 32px;
      box-sizing: border-box;
      box-shadow: 0px 2px 10px 0px rgba(71, 75, 75, 0.06);
      border-radius: 4px;

      .home-first-left-img,
      .home-first-right-img {
        width: 26px;
        height: 26px;
      }

      .home-first-left-title,
      .home-first-right-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-left: 14px;
      }

      .home-first-left-price,
      .home-first-right-price {
        font-size: 26px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #29d647;
        margin-left: 18px;
      }

      .home-first-right-price {
        color: #f9b63c;
      }
    }
    .home-first-right {
      margin-left: 20px;
      width: 50%;
      height: 90px;
    }
  }

  .home-second {
    margin-top: 10px;
    height: 420px;
    background: #fff;

    .home-second-block {
      padding: 20px 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .home-second-block-left {
        display: flex;
        align-items: center;
        height: 30px;
        margin-left: 24px;

        .second-block-left-title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }

        .second-block-left-price {
          font-size: 22px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #999999;
        }
      }

      .home-second-block-right {
        display: flex;
        height: 30px;
        position: relative;
        width: 180px;

        /deep/ .el-popper[x-placement^='bottom'] {
          font-size: 30px;
        }

        .home-second-block-right-date {
          font-weight: 400;
          font-size: 14px;
          color: rgb(16, 16, 16);
          margin-right: 20px;
        }

        /deep/ .el-input__inner {
          cursor: pointer;
          text-align: center;
        }
      }
    }
  }

  .home-third {
    margin-top: 10px;
    display: flex;
    .home-third-left {
      width: 50%;
      padding: 0 24px;
      box-sizing: border-box;
      background: #fff;

      .home-third-left-disflay {
        height: 40px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .home-third-left-block {
          display: flex;
          height: 40px;
          align-items: center;
          .left-block-people {
            margin-left: 10px;
            display: flex;
            align-items: center;
            height: 26px;
            .left-block-people-title {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }

            .left-block-people-text {
              font-size: 22px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #f9b63c;
            }
          }
        }

        .home-third-left-right {
          display: flex;
          height: 40px;
          align-items: center;
          width: 180px;

          /deep/ .el-input__inner {
            cursor: pointer;
            text-align: center;
          }
        }
      }

      .home-third-left-day {
        margin-top: 10px;
      }
    }

    .home-third-right {
      margin-left: 10px;
      width: 50%;
      background: #fff;
      .home-third-right-top {
        display: flex;
        height: 61px;
        align-items: center;
        padding: 0 20px 0 24px;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;

        .third-right-top-left {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }

        .third-right-top-right {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
      }

      .home-third-right-bottom {
        width: 100%;
        .third-right-bottom-list {
          height: 82px;
          padding: 0 20px 0 26px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          width: 100%;
          cursor: pointer;

          .bottom-list-div {
            width: 100%;
            display: flex;
            height: 30px;
            align-items: center;
            .bottom-list-div-title {
              display: flex;
              height: 30px;
              align-items: center;
              width: 75%;
              .list-div-img {
                width: 14px;
                height: 14px;
              }

              .list-div-title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #222222;
                margin-left: 11px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .bottom-list-div-time {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              width: 175px;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
